import React, { Fragment, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Layouts/Loader/Loader";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import MissionContainer from "./components/Mission";
import { CourseCreation } from "./components/apps/Courses/CourseCreation";
import { YearCreation } from "./components/apps/Years/YearCreation";

const Auth = lazy(
  () => import("./components/Authentication/firebaseAuth/auth")
);
const App = lazy(() => import("./components/app"));

const Counters = lazy(() => import("./components/apps/Counters/Counters"));

const Footers = lazy(() => import("./components/apps/Footers/Footers"));

const Loaders = lazy(() => import("./components/apps/Loaders/Loaders"));
const Notifications = lazy(
  () => import("./components/apps/Notifications/Notifications")
);
const Userlist = lazy(() => import("./components/apps/Userlist/Userlist"));
const Reports = lazy(() => import("./components/apps/Report"));
const Years = lazy(() => import("./components/apps/Years"));
const Questions = lazy(() => import("./components/apps/Questions"));
const Feedbacks = lazy(() => import("./components/apps/Feedbacks"));
const Courses = lazy(() => import("./components/apps/Courses"));
const Missions = lazy(() => import("./components/apps/Missions"));
const Classelist = lazy(() => import("./components/apps/ClassList/ClassList"));
const Error401 = lazy(
  () => import("./components/Authentication/errorPage/Error401/Error401")
);
const Error403 = lazy(
  () => import("./components/Authentication/errorPage/Error403/Error403")
);
const Error404 = lazy(
  () => import("./components/Authentication/errorPage/Error404/Error404")
);
const Error503 = lazy(
  () => import("./components/Authentication/errorPage/Error503/Error503")
);
const ForgotPassword = lazy(
  () => import("./components/Authentication/Forgot Password/ForgotPassword")
);
const LockScreen = lazy(
  () => import("./components/Authentication/LockScreen/LockScreen")
);
const Register = lazy(
  () => import("./components/Authentication/Register/Register")
);
const Accordians = lazy(
  () => import("./components/bootstrap/Accordians/Accordians")
);
const BootstrapAlerts = lazy(
  () => import("./components/bootstrap/bootstrapAlerts/bootstrapAlerts")
);
const AvatarRadius = lazy(
  () => import("./components/bootstrap/AvatarRadius/AvatarRadius")
);
const AvatarSquare = lazy(
  () => import("./components/bootstrap/AvatarSquare/AvatarSquare")
);
const AvatarRounded = lazy(
  () => import("./components/bootstrap/AvatarRounded/AvatarRounded")
);
const BadgesPills = lazy(
  () => import("./components/bootstrap/BadgesPills/BadgesPills")
);
const Breadcrumbs = lazy(
  () => import("./components/bootstrap/Breadcrumbs/Breadcrumbs")
);
const Buttons = lazy(() => import("./components/bootstrap/Buttons/Buttons"));
const Carousels = lazy(
  () => import("./components/bootstrap/Carousels/Carousels")
);
const Colors = lazy(() => import("./components/bootstrap/Colors/Colors"));
const DropDowns = lazy(
  () => import("./components/bootstrap/DropDowns/DropDowns")
);
const ListGroups = lazy(
  () => import("./components/bootstrap/ListGroup/ListGroups")
);
const MediaObject = lazy(
  () => import("./components/bootstrap/MediaObject/MediaObject")
);
const Modals = lazy(() => import("./components/bootstrap/Modal/Modal"));
const Navigation = lazy(
  () => import("./components/bootstrap/Navigation/Navigation")
);
const OffCanvas = lazy(
  () => import("./components/bootstrap/OffCanvas/OffCanvas")
);
const Pagination = lazy(
  () => import("./components/bootstrap/Pagination/Pagination")
);
const Panels = lazy(() => import("./components/bootstrap/Panels/Panels"));
const Progress = lazy(() => import("./components/bootstrap/Progress/Progress"));
const Tabs = lazy(() => import("./components/bootstrap/Tabs/Tabs"));
const Tags = lazy(() => import("./components/bootstrap/Tags/Tags"));
const Thumbnails = lazy(
  () => import("./components/bootstrap/Thumbnails/Thumbnails")
);
const Toast = lazy(() => import("./components/bootstrap/Toast/Toast"));
const TooltipPopover = lazy(
  () => import("./components/bootstrap/TooltipPopover/TooltipPopover")
);
const Typography = lazy(
  () => import("./components/bootstrap/Typography/Typography")
);
const Ribbons = lazy(() => import("./components/bootstrap/Ribbons/Ribbons"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));

const BootstrapIcons = lazy(
  () => import("./components/Icons/BootstrapIcons/BootstrapIcons")
);
const FeatherIcons = lazy(
  () => import("./components/Icons/FeatherIcons/FeatherIcons")
);
const FlagIcons = lazy(() => import("./components/Icons/FlagIcons/FlagIcons"));
const FontAwesome = lazy(
  () => import("./components/Icons/FontAwesome/FontAwesome")
);
const IonicIcons = lazy(
  () => import("./components/Icons/IonicIcons/IonicIcons")
);
const MaterialDesignIcons = lazy(
  () => import("./components/Icons/MaterialDesignIcons/MaterialDesignIcons")
);
const Pe7Icons = lazy(() => import("./components/Icons/Pe7Icons/Pe7Icons"));
const SimpleLineIcons = lazy(
  () => import("./components/Icons/SimpleLineIcons/SimpleLineIcons")
);
const ThemifyIcons = lazy(
  () => import("./components/Icons/ThemifyIcons/ThemifyIcons")
);
const TypiconsIcons = lazy(
  () => import("./components/Icons/TypiconsIcons/TypiconsIcons")
);
const WeatherIcons = lazy(
  () => import("./components/Icons/WeatherIcons/WeatherIcons")
);

const Editprofile = lazy(
  () => import("./components/pages/Editprofile/Editprofile")
);
const AboutCompany = lazy(
  () => import("./components/pages/Extension/AboutCompany/AboutCompany")
);
const BlogDetails = lazy(
  () => import("./components/pages/Extension/BlogDetails/BlogDetails")
);
const BlogPost = lazy(
  () => import("./components/pages/Extension/BlogPost/BlogPost")
);
const Blogs = lazy(() => import("./components/pages/Extension/Blogs/Blogs"));
const EmptyPage = lazy(
  () => import("./components/pages/Extension/EmptyPage/EmptyPage")
);
const FAQS = lazy(() => import("./components/pages/Extension/FAQS/FAQS"));
const Invoice = lazy(
  () => import("./components/pages/Extension/Invoice/Invoice")
);
const Pricing = lazy(
  () => import("./components/pages/Extension/Pricing/Pricing")
);
const Services = lazy(
  () => import("./components/pages/Extension/Services/Services")
);
const Settings = lazy(
  () => import("./components/pages/Extension/Settings/Settings")
);
const Terms = lazy(() => import("./components/pages/Extension/Terms/Terms"));
const UnderConstruction = lazy(
  () =>
    import("./components/pages/Extension/UnderConstruction/UnderConstruction")
);
const FormAdvanced = lazy(
  () => import("./components/pages/forms/FormAdvanced/FormAdvanced")
);
const FormEditor = lazy(
  () => import("./components/pages/forms/FormEditor/FormEditor")
);
const FormElements = lazy(
  () => import("./components/pages/forms/FormElements/FormElements")
);
const FormLayouts = lazy(
  () => import("./components/pages/forms/FormLayouts/FormLayouts")
);
const FormValidation = lazy(
  () => import("./components/pages/forms/FormValidation/FormValidation")
);
const FormWizard = lazy(
  () => import("./components/pages/forms/FormWizard/FormWizard")
);
const UnitCreate = lazy(() => import("./components/Units/UnitCreate"));

const MultipleChoice = lazy(
  () => import("./components/Questions/MultipleChoice/index")
);

const OrdenationDrag = lazy(
  () => import("./components/Questions/OrdenationDrag/index")
);

const Similarity = lazy(
  () => import("./components/Questions/Similarity/index")
);

const Relationship = lazy(
  () => import("./components/Questions/Relationship/index")
);
const Administrators = lazy(() => import("./components/Administrators/index"));

const Administrator = lazy(() => import("./components/Administrator/index"));

const BinaryChoice = lazy(() => import("./components/Questions/BinaryChoice"));
const Ordenation = lazy(() => import("./components/Questions/Ordenation"));

const Classes = lazy(() => import("./components/Classes/index"));

const Gallery = lazy(() => import("./components/pages/Gallery/Gallery"));
const MailCompose = lazy(
  () => import("./components/pages/MailCompose/MailCompose")
);
const MailInbox = lazy(() => import("./components/pages/MailInbox/MailInbox"));
const MailRead = lazy(() => import("./components/pages/MailRead/MailRead"));
const NotificationList = lazy(
  () => import("./components/pages/NotificationList/NotificationList")
);
const Profile = lazy(() => import("./components/pages/Profile/Profile"));
const DataTable = lazy(
  () => import("./components/pages/tables/DataTable/DataTable")
);
const DefaultTable = lazy(
  () => import("./components/pages/tables/DefaultTable/DefaultTable")
);
const EditTable = lazy(
  () => import("./components/pages/tables/EditTable/EditTable")
);

const FormInputSpinners = lazy(
  () => import("./components/pages/forms/FormInputSpinners/FormInputSpinners")
);
const CustomPage = lazy(() => import("./components/CustomPage"));
const Error400 = lazy(
  () => import("./components/Authentication/errorPage/Error400/Error400")
);
const Error500 = lazy(
  () => import("./components/Authentication/errorPage/Error500/Error500")
);
const Login = lazy(() => import("./components/Authentication/Login/Login"));
const AuthenticationPage = lazy(
  () => import("./components/AuthenticationPage")
);
const ErrorPages = lazy(() => import("./components/ErrorPages"));
const Switcherapp = lazy(() => import("./components/Switcherapp"));
const Landing = lazy(() => import("./components/Landing"));

const AuthLogin = lazy(
  () => import("./components/Authentication/firebaseAuth/AuthLogin")
);
const SignUp = lazy(
  () => import("./components/Authentication/firebaseAuth/Signup")
);

const container: HTMLElement | any = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
              <Route index element={<AuthLogin />} />
              <Route
                path={`${process.env.PUBLIC_URL}/Authentication/firebaseAuth/login`}
                element={<AuthLogin />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/Authentication/firebaseAuth/SignUp`}
                element={<SignUp />}
              />
            </Route>
            <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                element={<Dashboard />}
              />

              {/* Apps */}
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/create`}
                  element={<Administrator />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/admin/list`}
                  element={<Administrators />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/apps/counter`}
                  element={<Counters />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/questions/multipleChoice/:id`}
                  element={<MultipleChoice />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/course/questions/ordenationDrag/:id`}
                  element={<OrdenationDrag />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/course/questions/similarity/:id`}
                  element={<Similarity />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/course/questions/relationship/:id`}
                  element={<Relationship />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/apps/footer`}
                  element={<Footers />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/apps/loader`}
                  element={<Loaders />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/apps/notification`}
                  element={<Notifications />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/apps/userlist`}
                  element={<Userlist />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/mission/list`}
                  element={<Missions />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/question/list`}
                  element={<Questions />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/feedback/list`}
                  element={<Feedbacks />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/courses/list`}
                  element={<Courses />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/create/:id`}
                  element={<CourseCreation />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/year/create/:id`}
                  element={<YearCreation />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/classes/list`}
                  element={<Classelist />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/students/userlist`}
                  element={<Userlist />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/reports`}
                  element={<Reports />}
                />
                ,
              </Route>

              {/* bootstrap */}
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/accordian`}
                  element={<Accordians />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/bootstrapalerts`}
                  element={<BootstrapAlerts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/avatarradius`}
                  element={<AvatarRadius />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/avatarsquare`}
                  element={<AvatarSquare />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/avatarrounded`}
                  element={<AvatarRounded />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/badgespills`}
                  element={<BadgesPills />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/breadcrumbs`}
                  element={<Breadcrumbs />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/buttons`}
                  element={<Buttons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/carousels`}
                  element={<Carousels />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/colors`}
                  element={<Colors />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/dropdowns`}
                  element={<DropDowns />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/listgroups`}
                  element={<ListGroups />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/mediaobject`}
                  element={<MediaObject />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/modal`}
                  element={<Modals />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/navigation`}
                  element={<Navigation />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/offcanvas`}
                  element={<OffCanvas />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/pagination`}
                  element={<Pagination />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/panels`}
                  element={<Panels />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/progress`}
                  element={<Progress />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/tabs`}
                  element={<Tabs />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/tags`}
                  element={<Tags />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/thumbnails`}
                  element={<Thumbnails />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/toast`}
                  element={<Toast />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/tooltippopover`}
                  element={<TooltipPopover />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/typography`}
                  element={<Typography />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/bootstrap/ribbons`}
                  element={<Ribbons />}
                />
                ,
              </Route>

              {/* Icons */}
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/bootstrapicons`}
                  element={<BootstrapIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/feathericons`}
                  element={<FeatherIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/flagicons`}
                  element={<FlagIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/fontawesome`}
                  element={<FontAwesome />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/ionicicons`}
                  element={<IonicIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/materialdesignicons`}
                  element={<MaterialDesignIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/pe7icons`}
                  element={<Pe7Icons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/simplelineicons`}
                  element={<SimpleLineIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/themifyicons`}
                  element={<ThemifyIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/typiconsicons`}
                  element={<TypiconsIcons />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/icons/weathericons`}
                  element={<WeatherIcons />}
                />
                ,
              </Route>

              {/* Pages */}
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/editprofile`}
                  element={<Editprofile />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/aboutcompany`}
                  element={<AboutCompany />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/blogdetails`}
                  element={<BlogDetails />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/blogpost`}
                  element={<BlogPost />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/blogs`}
                  element={<Blogs />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/emptypage`}
                  element={<EmptyPage />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/faqs`}
                  element={<FAQS />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/invoice`}
                  element={<Invoice />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/pricing`}
                  element={<Pricing />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/services`}
                  element={<Services />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/setting`}
                  element={<Settings />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/extension/term`}
                  element={<Terms />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/formadvanced`}
                  element={<FormAdvanced />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/formeditor`}
                  element={<FormEditor />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/formelements`}
                  element={<FormElements />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/formlayouts`}
                  element={<FormLayouts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/formvalidation`}
                  element={<FormValidation />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/forminputspinner`}
                  element={<FormInputSpinners />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/forms/formwizard`}
                  element={<FormWizard />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/years`}
                  element={<Years />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/units/create`}
                  element={<UnitCreate />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/mission/:id`}
                  element={<MissionContainer />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/questions/binaryChoice/:id`}
                  element={<BinaryChoice />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/questions/ordenation/:id`}
                  element={<Ordenation />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/course/classes/:id`}
                  element={<Classes />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/gallery`}
                  element={<Gallery />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/mailcompose`}
                  element={<MailCompose />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/mailinbox`}
                  element={<MailInbox />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/mailread`}
                  element={<MailRead />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/notificationlist`}
                  element={<NotificationList />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/profile`}
                  element={<Profile />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/tables/datatables`}
                  element={<DataTable />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/tables/edittables`}
                  element={<EditTable />}
                />
                ,
              </Route>
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/pages/tables/defaulttables`}
                  element={<DefaultTable />}
                />
                ,
              </Route>
            </Route>

            {/* Authentication Pages */}
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<AuthenticationPage />}
            >
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/login`}
                element={<Login />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/register`}
                element={<Register />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/forgotpassword`}
                element={<ForgotPassword />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/lockscreen`}
                element={<LockScreen />}
              />
              ,
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/`} element={<CustomPage />}>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/extension/underconstruction`}
                element={<UnderConstruction />}
              />
            </Route>

            {/* Authentication Error Pages */}
            <Route path={`${process.env.PUBLIC_URL}`} element={<ErrorPages />}>
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/errorpage/error400`}
                element={<Error400 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/errorpage/error401`}
                element={<Error401 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/errorpage/error403`}
                element={<Error403 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/errorpage/error404`}
                element={<Error404 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/errorpage/error500`}
                element={<Error500 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/authentication/errorpage/error503`}
                element={<Error503 />}
              />
              ,
            </Route>
            {/* Firebase Authentication */}

            <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/pages/switcher/switcherstyle1`}
                element={<Switcherapp />}
              />
              ,
            </Route>
            <Route>
              <Route
                path={`${process.env.PUBLIC_URL}/landingPage/landingPage`}
                element={<Landing />}
              />
            </Route>
            <Route path="*" element={<Error500 />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
