import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import "react-stepzilla/src/css/main.css";
import { storage } from "../../../Firebase/firebase";
import { generateRandomString } from "../../../utils/Helpers/uuid";
import { Course } from "../../Questions/QuestionModel";
import FileUploader from "react-firebase-file-uploader";
import {
  createCourse,
  getCourseById,
  updateCourse,
} from "../../../api/course.api";

const courseDefault: Course = {
  id: generateRandomString(),
  name: "",
  order: 0,
  svgIconUrl: "",
};

export const CourseCreation = (props) => {
  const [err, setError] = useState("");
  const { id } = useParams();
  const [courseId, setCourseId] = useState(id || "");
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [course, setCourse] = useState<Course>(courseDefault);
  const navigate = useNavigate();
  const goToCourses = () => {
    let path = `${process.env.PUBLIC_URL}/courses/list`;
    navigate(path);
  };
  const getCourse = async (id: string) => {
    const result = await getCourseById(id);
    if (result) {
      setCourse(result);
    }
  };
  useEffect(() => {
    if (courseId) {
      getCourse(courseId);
    }
  }, [courseId]);

  const htmlSanitizer = (str) => {
    var regex = /<br\s*\/?>$/i;
    return str.replace(regex, "");
  };

  const resetAllStates = () => {
    setCourse({ ...courseDefault, id: generateRandomString() });
    setCourseId("0");
    setError("");
  };

  const handleClick = async () => {
    const message = validateCourse(course);
    if (!validate(course)) {
      setError(message);
    } else {
      setError("");
      try {
        if (courseId !== "0") {
          await updateCourse(course);
          goToCourses();
        } else {
          try {
            await createCourse(course);
            goToCourses();
          } catch (error) {
            alert(
              "Tivemos um problema ao salvar o componente curricular: " + error
            );
          } finally {
            resetAllStates();
          }
        }
      } catch (error) {
        alert(
          "Tivemos um problema ao salvar o componente curricular: " + error
        );
      } finally {
        resetAllStates();
      }
    }
  };

  function validate(course) {
    if (!course.name) {
      return false;
    }

    return true;
  }

  const changeOrder = (order: number) => {
    setCourse((prev) => {
      return {
        ...prev,
        order,
      };
    });
  };
  const changeName = (name: string) => {
    name = htmlSanitizer(name);
    if (name === "<p><br></p>") name = "";
    name = name.replace("<br></p>", "</p>");

    setCourse((prev) => {
      return {
        ...prev,
        name,
      };
    });
  };
  const handleUploadSuccess = (filename) => {
    setLoadingIcon(true);
    storage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        setLoadingIcon(false);
        setCourse((prev) => {
          return {
            ...prev,
            svgIconUrl: url,
          };
        });
      });
  };
  const validateCourse = (course: Course) => {
    if (!course.name) {
      return "O nome do componente curricular é obrigatório";
    }

    return "";
  };

  return (
    <div className="row borders">
      <div className="control-group form-group">
        <label className="form-label">Ordem</label>
        <input
          type="number"
          value={course?.order}
          onChange={(e) => {
            changeOrder(parseInt(e.target.value));
          }}
          className="form-control required"
          placeholder="Ordem"
        />
      </div>
      <div className="col-xl-8 p-4">
        <section>
          <div className="mg-t-20">
            <label className="form-label">Nome</label>

            <input
              type="text"
              value={course?.name}
              onChange={(e) => {
                changeName(e.target.value);
              }}
              className="form-control required"
              placeholder="Nome do componente curricular"
            />
          </div>
          <label className="form-label">Icone</label>
          <label
            style={{
              backgroundColor: "steelblue",
              color: "white",
              minWidth: 130,
              padding: 10,
              borderRadius: 4,
              cursor: "pointer",
            }}
          >
            Selecione o ícone
            <FileUploader
              hidden
              accept="image/*"
              name="avatar"
              randomizeFilename
              storageRef={storage.ref("images")}
              onUploadSuccess={handleUploadSuccess}
              onUploadError={(error) => {
                alert(error);
              }}
            />
          </label>
          {loadingIcon && (
            <div className="spinner-border text-primary" role="status"></div>
          )}
          {course.svgIconUrl && (
            <img
              src={course.svgIconUrl}
              alt="uploaded file"
              height={50}
              style={{
                marginLeft: 25,
              }}
            />
          )}
        </section>
        {err && (
          <Alert style={{ marginTop: 20 }} variant="danger">
            {err}
          </Alert>
        )}
      </div>

      <div className="col-xl-3 border-right"></div>
      <div className="col-xl-8 p-4">
        <button
          className="btn btn-primary mt-2 float-start mt-4"
          onClick={() => handleClick()}
        >
          Salvar
        </button>
      </div>
    </div>
  );
};
