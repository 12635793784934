const ENV = {
  dev: {
    STUDENTS: "acerta_students_dev",
    YEARS: "acerta_years_dev",
    CURRENT_ENVIRONMENT: "dev",
    COURSES: "acerta_courses_dev",
    STUDENT_COURSES: "acerta_student_courses_dev",
    MISSIONS: "acerta_missions_dev",
    STUDENT_MISSIONS: "acerta_student_missions_dev",
    MISSION_LEVELS: "acerta_mission_levels_dev",
    QUESTIONS: "acerta_questions_dev",
    FEEDBACK: "acerta_feedback_dev",
  },
  uat: {
    STUDENTS: "acerta_student_uat",
    YEARS: "acerta_year_uat",
    CURRENT_ENVIRONMENT: "uat",
    COURSES: "acerta_course_uat",
    STUDENT_COURSES: "acerta_student_course_uat",
    MISSIONS: "acerta_mission_uat",
    STUDENT_MISSIONS: "acerta_student_mission_uat",
    MISSION_LEVELS: "acerta_mission_level_uat",
    QUESTIONS: "acerta_question_uat",
    FEEDBACK: "acerta_feedback_uat",
  },
  prod: {
    STUDENTS: "acerta_students_prod",
    YEARS: "acerta_years_prod",
    CURRENT_ENVIRONMENT: "prod",
    COURSES: "acerta_courses_prod",
    STUDENT_COURSES: "acerta_student_courses_prod",
    MISSIONS: "acerta_missions_prod",
    STUDENT_MISSIONS: "acerta_student_missions_prod",
    MISSION_LEVELS: "acerta_mission_levels_prod",
    QUESTIONS: "acerta_questions_prod",
    FEEDBACK: "acerta_feedback_prod",
  },
};

const getEnvVars = () => {
  if (process.env.NODE_ENV === "development") return ENV.dev;
  return ENV.prod;
};

export default getEnvVars;
export { ENV };
