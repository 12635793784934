import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import "react-stepzilla/src/css/main.css";

import { generateRandomString } from "../../../utils/Helpers/uuid";
import { Year } from "../../Questions/QuestionModel";
import { createYear, getYearById, updateYear } from "../../../api/year.api";

const yearDefault: Year = {
  id: generateRandomString(),
  name: "",
  yearInNumber: 0,
};

export const YearCreation = (props) => {
  const [err, setError] = useState("");
  const { id } = useParams();
  const [yearId, setYearId] = useState(id || "");

  const [year, setYear] = useState<Year>(yearDefault);
  const navigate = useNavigate();
  const goToYears = () => {
    let path = `${process.env.PUBLIC_URL}/years`;
    navigate(path);
  };
  const getYear = async (id: string) => {
    const result = await getYearById(id);
    if (result) {
      setYear(result);
    }
  };
  useEffect(() => {
    if (yearId) {
      getYear(yearId);
    }
  }, [yearId]);

  const htmlSanitizer = (str) => {
    var regex = /<br\s*\/?>$/i;
    return str.replace(regex, "");
  };

  const resetAllStates = () => {
    setYear({ ...yearDefault, id: generateRandomString() });
    setYearId("0");
    setError("");
  };

  const handleClick = async () => {
    const message = validateYear(year);
    if (!validate(year)) {
      setError(message);
    } else {
      setError("");
      try {
        if (yearId !== "0") {
          await updateYear(year);
          goToYears();
        } else {
          try {
            await createYear(year);
            goToYears();
          } catch (error) {
            alert(
              "Tivemos um problema ao salvar o componente curricular: " + error
            );
          } finally {
            resetAllStates();
          }
        }
      } catch (error) {
        alert(
          "Tivemos um problema ao salvar o componente curricular: " + error
        );
      } finally {
        resetAllStates();
      }
    }
  };

  function validate(year) {
    if (!year.name) {
      return false;
    }
    if (!year.yearInNumber) {
      return false;
    }

    return true;
  }

  const changeOrder = (yearInNumber: number) => {
    setYear((prev) => {
      return {
        ...prev,
        yearInNumber,
      };
    });
  };
  const changeName = (name: string) => {
    name = htmlSanitizer(name);
    if (name === "<p><br></p>") name = "";
    name = name.replace("<br></p>", "</p>");

    setYear((prev) => {
      return {
        ...prev,
        name,
      };
    });
  };

  const validateYear = (year: Year) => {
    if (!year.name) {
      return "O nome do ano é obrigatório";
    }
    if (!year.yearInNumber) {
      return "O ano em número é obrigatório";
    }

    return "";
  };

  return (
    <div className="row borders">
      <div className="control-group form-group">
        <label className="form-label">Ano em número</label>
        <input
          type="number"
          value={year?.yearInNumber}
          onChange={(e) => {
            changeOrder(parseInt(e.target.value));
          }}
          className="form-control required"
          placeholder="Ex: 6"
        />
      </div>
      <div className="col-xl-8 p-4">
        <section>
          <div className="mg-t-20">
            <label className="form-label">Nome</label>
            <input
              type="text"
              value={year?.name}
              onChange={(e) => {
                changeName(e.target.value);
              }}
              className="form-control required"
              placeholder="Ex: 6° Ano"
            />
          </div>
        </section>
        {err && (
          <Alert style={{ marginTop: 20 }} variant="danger">
            {err}
          </Alert>
        )}
      </div>

      <div className="col-xl-3 border-right"></div>
      <div className="col-xl-8 p-4">
        <button
          className="btn btn-primary mt-2 float-start mt-4"
          onClick={() => handleClick()}
        >
          Salvar
        </button>
      </div>
    </div>
  );
};
