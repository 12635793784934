import React from "react";

const stepsData = [
  { title: "Informações Iniciais", order: 0 },
  { title: "Introdução", order: 1 },
  { title: "Pré-Textual", order: 2 },
];

const StepsMenu = ({ currentStep, setCurrentStep }) => (
  <div className="col-xl-3 border-right">
    {stepsData.map((step) => (
      <div className="Stepperh3 d-flex p-2" key={step.order}>
        <span
          className={`number ${
            currentStep === step.order ? "step3" : "step2"
          } me-2`}
        >
          {step.order + 1}
        </span>
        <span
          className={`title ${currentStep !== step.order ? "Step1" : ""} `}
          onClick={() => setCurrentStep(step.order)}
        >
          {step.title}
        </span>
      </div>
    ))}
  </div>
);

export default StepsMenu;
