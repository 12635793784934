import firebase from "firebase/compat/app";
import { db } from "../Firebase/firebase";
import getEnvVars from "../environments/environment";
import { generateRandomString } from "../utils/Helpers/uuid";
import { Year } from "../components/Questions/QuestionModel";
import { getCurrentUserEmail } from "./user.api";

export const getAllYears = async (): Promise<Year[]> => {
  const collectionRef = db.collection(getEnvVars().YEARS);
  const filteredRef = collectionRef.where("deleted", "!=", true);

  const snapshot = await filteredRef.get();
  const items: Year[] = [];
  snapshot.forEach((doc) => {
    const item = doc.data() as Year;
    items.push(item);
  });
  return items.sort((a, b) => a.yearInNumber - b.yearInNumber);
};

export const getYearById = async (id: string): Promise<Year> => {
  const collectionRef = db.collection(getEnvVars().YEARS);
  const doc = await collectionRef.doc(id).get();
  return doc.data() as Year;
};

export const deleteYearById = async (id: string) => {
  const collectionRef = db.collection(getEnvVars().YEARS);
  try {
    await collectionRef.doc(id).update({
      deleted: true,
      deletedAt: getFirebaseTimestamp(),
      deletedBy: getCurrentUserEmail(),
    });
    alert("Ano deletado com sucesso!");
  } catch (error) {}
};

const createNewYear = async (year: Year, newId: string) => {
  const newYear = { ...year, id: newId };
  const yearRef = db.collection(getEnvVars().YEARS).doc(newId);
  try {
    await yearRef.set(newYear);
    alert("Ano adicionado com sucesso!");
  } catch (error) {
    console.error("Erro ao adicionar o ano: ", error);
  }
};

const addExistingYear = async (year: Year) => {
  const yearRef = db.collection(getEnvVars().YEARS).doc(year.id);
  try {
    await yearRef.set(year);
    alert("Ano adicionado com sucesso!");
  } catch (error) {
    console.error("Erro ao adicionar o ano: ", error);
  }
};

const getFirebaseTimestamp = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const createYear = async (year: Year) => {
  const yearRef = db.collection(getEnvVars().YEARS).doc(year.id);
  try {
    const doc = await yearRef.get();
    if (doc.exists) {
      const newId = generateRandomString();
      await createNewYear(
        {
          ...year,
          deleted: false,
          createdAt: getFirebaseTimestamp(),
          createdBy: getCurrentUserEmail(),
        },
        newId
      );
    } else {
      await addExistingYear({
        ...year,
        deleted: false,
        createdAt: getFirebaseTimestamp(),
        createdBy: getCurrentUserEmail(),
      });
    }
  } catch (error: any) {
    alert("Erro ao obter o documento: " + error);
  }
};

export const updateYear = async (year: Year) => {
  const collectionRef = db.collection(getEnvVars().YEARS);
  try {
    await collectionRef.doc(year.id).update({
      ...year,
      updatedAt: getFirebaseTimestamp(),
      updatedBy: getCurrentUserEmail(),
    });
    alert("Ano atualizado com sucesso!");
  } catch (error) {
    alert("Erro ao atualizar o ano: " + error);
  }
};
