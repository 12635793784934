import React, { FC } from "react";
type FooterStepsProps = {
  goSteps: number;
  setGoSteps: (step: number) => void;
  onSave?: () => void;
  validateMissionFields: (mission: any) => {
    isError: boolean;
    message: string;
  };
  mission: any;
  setError: (err: string) => void;
  additionalButton?: string;
  validateAdditionalButton?: (mission: any) => {
    isError: boolean;
    message: string;
  };
  missionLevels: any;
  currentLevel?: number;
  PageType: any;
  addNewPage?: (id: string, pageType: any) => void;
};

const FooterSteps: FC<FooterStepsProps> = ({
  goSteps,
  setGoSteps,
  onSave,
  validateMissionFields,
  mission,
  setError,
  additionalButton,
  validateAdditionalButton,
  missionLevels,
  currentLevel = 0,
  PageType,
  addNewPage,
}) => (
  <div className="col-sm-8 p-4">
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {additionalButton && (
        <button
          disabled={currentLevel === 0}
          style={{ alignSelf: "flex-end" }}
          className="btn btn-primary float-end mt-4"
          onClick={() => {
            const currentPageType =
              missionLevels[currentLevel - 1][PageType] || [];
            const validade = validateAdditionalButton?.(
              currentPageType[currentPageType.length - 1]
            );
            if (validade?.isError) return setError(validade?.message);
            addNewPage?.(missionLevels[currentLevel - 1].id, PageType);
          }}
        >
          {additionalButton}
        </button>
      )}
    </div>
    {goSteps < 2 ? (
      <button
        className="btn btn-primary float-end mt-4"
        onClick={() => {
          const validade = validateMissionFields(mission);
          if (validade.isError) return setError(validade.message);
          setError("");
          setGoSteps(goSteps + 1);
        }}
      >
        Próximo
      </button>
    ) : (
      <button className="btn btn-primary float-end mt-4" onClick={onSave}>
        Salvar
      </button>
    )}
    <button
      className="btn btn-primary float-start mt-4"
      onClick={() => setGoSteps(goSteps - 1)}
    >
      Anterior
    </button>
  </div>
);
export default FooterSteps;
