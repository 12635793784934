import { db } from "../Firebase/firebase";
import getEnvVars from "../environments/environment";
import firebase from "firebase/compat/app";
import { getCurrentUserEmail } from "./user.api";

const aasignCourseToStudents = async (studentId: string) => {
  const coursesSnapshot = await db.collection(getEnvVars().COURSES).get();

  coursesSnapshot.forEach((doc) => {
    const courseId = doc.data().id;

    const newDocId = db.collection(getEnvVars().STUDENT_COURSES).doc().id;

    db.collection(getEnvVars().STUDENT_COURSES)
      .doc(newDocId)
      .set({
        studentId: studentId,
        courseId: courseId,
        id: newDocId,
        experience: 0,
      })
      .then(() => {})
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  });
};

const assignStudentToMissions = async (studentId: string, yearId: string) => {
  // Buscar todas as missões do ano especificado
  const missionsSnapshot = await db
    .collection(getEnvVars().MISSIONS)
    .where("yearId", "==", yearId)
    .get();

  // Agora, para cada missão, vamos adicionar o novo estudante
  missionsSnapshot.forEach((doc) => {
    const missionId = doc.data().id;

    // Criar um id aleatório para o novo documento
    const newDocId = db.collection(getEnvVars().STUDENT_MISSIONS).doc().id;

    // Criar um novo documento na coleção 'student_missions_dev'
    db.collection(getEnvVars().STUDENT_MISSIONS)
      .doc(newDocId)
      .set({
        studentId: studentId,
        yearId: yearId,
        missionId: missionId,
        id: newDocId,
        level: 0,
        isActive: true,
      })
      .then(() => {})
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  });
};

export const createStudentContract = async (
  yearId: string,
  studentId: string
) => {
  assignStudentToMissions(studentId, yearId);
  aasignCourseToStudents(studentId);
};
const getFirebaseTimestamp = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};
export const createMission = async (
  mission: any,
  missionLevels: MissionLevel[]
) => {
  const newDocId = db.collection(getEnvVars().MISSIONS).doc().id;

  db.collection(getEnvVars().MISSIONS)
    .doc(newDocId)
    .set({
      ...mission,
      id: newDocId,
      createdAt: getFirebaseTimestamp(),
      createdBy: getCurrentUserEmail(),
    })
    .then(async () => {
      await createMissionLevels(newDocId, missionLevels);
      await assignMissionToStudents(newDocId, mission.yearId);
    })
    .catch((error) => {
      console.error("CreateMission error adding document: ", error);
    });
};

export const createMissionLevels = async (
  missionId: string,
  missionLevels: MissionLevel[]
) => {
  const missionLevelsTemp: MissionLevel[] = JSON.parse(
    JSON.stringify(missionLevels)
  );

  missionLevelsTemp.forEach((missionLevel) => {
    const newDocId = db.collection(getEnvVars().MISSION_LEVELS).doc().id;
    db.collection(getEnvVars().MISSION_LEVELS)
      .doc(newDocId)
      .set({
        ...missionLevel,
        missionId: missionId,
        id: newDocId,
        createdAt: getFirebaseTimestamp(),
        createdBy: getCurrentUserEmail(),
      })
      .then(async () => {})
      .catch((error) => {
        console.error("Error adding document mission Nivel: ", error);
      });
  });
};

const assignMissionToStudents = async (missionId: string, yearId: string) => {
  // Buscar todos os estudantes do ano especificado
  const studentsSnapshot = await db
    .collection(getEnvVars().STUDENTS)
    .where("yearId", "==", yearId)
    .get();

  // Agora, para cada estudante, vamos adicionar a nova missão
  studentsSnapshot.forEach((doc) => {
    const studentId = doc.data().id;

    // Criar um id aleatório para o novo documento
    const newDocId = db.collection(getEnvVars().STUDENT_MISSIONS).doc().id;

    // Criar um novo documento na coleção 'student_missions_dev'
    db.collection(getEnvVars().STUDENT_MISSIONS)
      .doc(newDocId)
      .set({
        studentId: studentId,
        yearId: yearId,
        missionId: missionId,
        id: newDocId,
        level: 0,
        isActive: true,
        createdAt: getFirebaseTimestamp(),
        createdBy: getCurrentUserEmail(),
      })
      .then(() => {})
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  });
};

export const getMissionById = async (missionId: string) => {
  const missionSnapshot = await db
    .collection(getEnvVars().MISSIONS)
    .doc(missionId)
    .get();

  return missionSnapshot.data() as Promise<Mission>;
};

export const getMissionLevelsByMissionId = async (missionId: string) => {
  const missionLevelsSnapshot = await db
    .collection(getEnvVars().MISSION_LEVELS)
    .orderBy("level", "asc")
    .where("missionId", "==", missionId)
    .get();

  const missionLevels: MissionLevel[] = [];

  missionLevelsSnapshot.forEach((doc) => {
    missionLevels.push(doc.data() as MissionLevel);
  });
  return missionLevels.sort((a, b) => a.level - b.level);
};

export const updateMission = async (
  mission: any,
  missionLevels: MissionLevel[]
) => {
  const missionLevelsTemp: MissionLevel[] = JSON.parse(
    JSON.stringify(missionLevels)
  );
  db.collection(getEnvVars().MISSIONS)
    .doc(mission.id)
    .update({
      ...mission,
      updatedAt: getFirebaseTimestamp(),
      updatedBy: getCurrentUserEmail(),
    })
    .then(async () => {
      await updateMissionLevels(missionLevelsTemp);
    })
    .catch((error) => {
      console.error("UpdateMission error adding document: ", error);
    });
};

const updateMissionLevels = async (missionLevels: MissionLevel[]) => {
  const missionLevelsTemp: MissionLevel[] = JSON.parse(
    JSON.stringify(missionLevels)
  );
  missionLevelsTemp.forEach((missionLevel) => {
    db.collection(getEnvVars().MISSION_LEVELS)
      .doc(missionLevel.id)
      .update({
        ...missionLevel,
        updatedAt: getFirebaseTimestamp(),
        updatedBy: getCurrentUserEmail(),
      })
      .then(async () => {})
      .catch((error) => {
        console.error("Error updating document mission Nivel: ", error);
      });
  });
};
