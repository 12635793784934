import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Form, Alert } from "react-bootstrap";
import "react-stepzilla/src/css/main.css";

import FileUploader from "react-firebase-file-uploader";
import { generateRandomString } from "../../utils/Helpers/uuid";
import FooterSteps from "../FooterSteps";
import StepsMenu from "../StepMenu";
import { storage } from "../../Firebase/firebase";
import MotiroEditor from "../MotiroEditor";
import {
  createMission,
  getMissionById,
  getMissionLevelsByMissionId,
  updateMission,
} from "../../api/Mission";
import { getAllCourses } from "../../api/course.api";
import { getAllYears } from "../../api/year.api";
interface Year {
  id: string;
  name: string;
  yearInNumber: number;
}
interface Course {
  id: string;
  name: string;
  order: number;
}

enum PageType {
  INTRODUCTION = "introduction",
  PRE_TEXTUAL = "preTextual",
  POS_TEXTUAL = "posTextual",
  QUESTIONS = "questions",
  STAY_ALERT = "stayAlert",
}

interface Mission {
  id: string;
  title: string;
  name: string;
  svgIconUrl: string;
  order: number;
  courseId: string;
  yearId: string;
  maxLevel: number;
}
interface MissionLevel {
  id: string;
  name: string;
  order: number;
  level: number;
  missionId: string;
  introduction: MissionModuleContent[];
  preTextual: MissionModuleContent[];
  posTextual: MissionModuleContent[];
  questions: MissionModuleContent[];
  stayAlert: MissionModuleContent[];
}
interface MissionModuleContent {
  id: string;
  order: number;
  htmlContent: string;
}

export type PayloadOptions = {
  id: string | number;
  description: string;
  correct?: boolean;
};
export type QuestionPayload = {
  subTitle: string;
  title: string;
  options: PayloadOptions[];
};

export type Question = {
  id: string;
  ordenated: number;
  missionId: string;
  missionLevel: number;
  payload: QuestionPayload;
};

const generateMissionLevel = (order: number, level: number) => {
  return JSON.parse(
    JSON.stringify({
      id: generateRandomString(),
      name: "",
      order: order,
      level: level,
      missionId: "",
      introduction: [],
      preTextual: [],
      posTextual: [],
      questions: [],
      stayAlert: [],
    })
  );
};

const getMissionLevels = () => {
  return Array.from({ length: 3 }, (_, i) => generateMissionLevel(i, i));
};

const missionLevelDefault: MissionLevel[] = getMissionLevels();

const missionDefault: Mission = {
  id: generateRandomString(),
  title: "",
  name: "",
  svgIconUrl: "",
  order: 0,
  courseId: "",
  yearId: "",
  maxLevel: 3,
};

export const MissionComponent = () => {
  const [err, setError] = useState("");
  const [courses, setCourses] = useState<Course[]>([]);
  const [loadingIcon, setLoadingIcon] = useState(false);
  const { id } = useParams();
  const [missionLevels, setMissionLevels] = useState<MissionLevel[]>(
    JSON.parse(JSON.stringify(missionLevelDefault))
  );
  const [years, setYears] = useState<Year[]>([]);
  const [goSteps, setGoSteps] = useState(0);
  const [questionId, setQuestionId] = useState(id);
  const [mission, setMission] = useState<any>(
    JSON.parse(JSON.stringify(missionDefault))
  );
  const [currentLevel, setCurrentLevel] = useState<number>(0);
  const navigate = useNavigate();
  const goToMissions = () => {
    let path = `${process.env.PUBLIC_URL}/mission/list/`;
    navigate(path);
  };
  const setMissionMaxLevel = async (maxLevel: number) => {
    setMission((prev) => {
      return {
        ...prev,
        maxLevel: maxLevel,
      };
    });
  };

  const addNewPage = (levelId: string, pageType: PageType) => {
    setMissionLevels((prev) => {
      return prev.map((item) => {
        if (item.id === levelId) {
          const pageArray = item[pageType] || [];
          return {
            ...item,
            [pageType]: [
              ...pageArray,
              {
                id: generateRandomString(),
                order:
                  pageArray.length === 0
                    ? 1
                    : pageArray[pageArray.length - 1]?.order + 1,
                htmlContent: "",
              },
            ],
          };
        }
        return item;
      });
    });
  };

  const resetAllDataAndStates = async () => {
    setMissionLevels(missionLevelDefault);
    setMission(missionDefault);
    setGoSteps(0);
    setCurrentLevel(0);
    setQuestionId(id);
    setError("");
  };

  const removePage = (levelId: string, pageId: string, pageType: PageType) => {
    setMissionLevels((prev) => {
      return prev.map((item) => {
        if (item.id === levelId) {
          return {
            ...item,
            [pageType]: item[pageType].filter((page) => page.id !== pageId),
          };
        }
        return item;
      });
    });
  };

  const validateMissionFields = (obj: Mission) => {
    const requiredFields = [
      { field: "yearId", name: "Ano" },
      { field: "courseId", name: "Componente curricular" },
      { field: "name", name: "Nome" },
      { field: "order", name: "Ordem" },
      { field: "svgIconUrl", name: "Ícone em SVG" },
    ];

    for (let i = 0; i < requiredFields.length; i++) {
      if (
        !obj.hasOwnProperty(requiredFields[i].field) ||
        obj[requiredFields[i].field] === undefined ||
        obj[requiredFields[i].field] === ""
      ) {
        return {
          isError: true,
          message: `O campo ${requiredFields[i].name} é obrigatório.`,
        };
      }
    }
    return {
      isError: false,
      message: "Todos os campos estão preenchidos corretamente.",
    };
  };

  const validateContentPageFields = (obj: MissionModuleContent) => {
    const requiredFields = [
      { field: "order", name: "Ordem" },
      { field: "htmlContent", name: "Conteúdo" },
    ];
    if (obj) {
      for (let i = 0; i < requiredFields.length; i++) {
        if (
          !obj.hasOwnProperty(requiredFields[i].field) ||
          obj[requiredFields[i].field] === undefined ||
          obj[requiredFields[i].field] === ""
        ) {
          return {
            isError: false,
            message: `Para adicionar uma nova página, preencha corretamente a página anterior, ${requiredFields[i].name} é obrigatório.`,
          };
        }
      }
    }
    return {
      isError: false,
      message: "Todos os campos estão preenchidos corretamente.",
    };
  };
  const loadMissionAndLevels = async (id: string) => {
    const mission = await getMissionById(id);
    setMission(mission);

    let missionLevels = await getMissionLevelsByMissionId(id);
    console.log(missionLevels[0]);
    setMissionLevels(missionLevels);
    // if (missionLevels.length === 0) {
    //   await createMissionLevels(id, missionLevelDefault);
    //   let missionLevels = await getMissionLevelsByMissionId(id);
    //   setMissionLevels(missionLevels);
    // } else {
    //   setMissionLevels(missionLevels);
    // }
  };

  useEffect(() => {
    if (questionId === null) return;
    if (questionId !== "0") {
      setCurrentLevel(1);
      questionId && loadMissionAndLevels(questionId);
    }
  }, [questionId]);

  const getCourses = async () => {
    const result = await getAllCourses();
    setCourses(result);
  };

  const getYears = async () => {
    const result = await getAllYears();
    setYears(result);
  };

  useEffect(() => {
    getCourses();
    getYears();
  }, []);

  async function hashImage(base64Data) {
    const data = new TextEncoder().encode(base64Data);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }

  const editImageFormat = async (content) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, "text/html");
    const images = htmlDoc.getElementsByTagName("img");

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const imageUrl = image.src;
      if (!imageUrl.startsWith("data:image")) {
        continue;
      }

      const base64Data = imageUrl.replace(/^data:image\/\w+;base64,/, "");
      const blob = await (
        await fetch(`data:image/png;base64,${base64Data}`)
      ).blob();

      const hash = await hashImage(base64Data);
      const {
        getStorage,
        ref,
        getDownloadURL,
        uploadBytesResumable,
      } = require("firebase/storage");
      const storage = getStorage();
      const storageRef = ref(storage, `images/${hash}.png`); // Use the hash as the file name

      // Check if the image already exists in storage
      let downloadURL;
      try {
        downloadURL = await getDownloadURL(storageRef);
      } catch (error: any) {
        const uploadTask = uploadBytesResumable(storageRef, blob);
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            () => {},
            (error) => reject(error),
            () => resolve(uploadTask.snapshot)
          );
        });
        downloadURL = await getDownloadURL(storageRef);
      }

      // Replace the image src with the download URL
      image.src = downloadURL;
    }

    // Set the new content with the replaced image URLs
    return htmlDoc.body.innerHTML;
  };

  const handleClick = async () => {
    try {
      const validate = validateMissionFields(mission);
      if (validate.isError) {
        setError(validate.message);
        return;
      }

      if (questionId === "0") {
        await createMission(mission, missionLevels);
        await resetAllDataAndStates();
        goToMissions();

        alert("Missão criada com sucesso!");
      } else {
        await updateMission(mission, missionLevels);
        await resetAllDataAndStates();
        goToMissions();

        alert("Missão atualizada com sucesso!");
      }
    } catch (error) {
      alert(error);
    }
  };

  const onChangeCourse = (id: string) => {
    setMission((prev) => {
      return {
        ...prev,
        courseId: id,
      };
    });
  };

  const onChangeYear = (year: string) => {
    setMission((prev) => {
      return {
        ...prev,
        yearId: year,
      };
    });
  };

  const handleUploadSuccess = (filename) => {
    setLoadingIcon(true);
    storage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        setLoadingIcon(false);

        setMission((prev) => {
          return {
            ...prev,
            svgIconUrl: url,
          };
        });
      });
  };

  const onChangeTitle = (title: string) => {
    setMission((prev) => {
      return {
        ...prev,
        title: title,
      };
    });
  };

  const onChangeName = (name: string) => {
    setMission((prev) => {
      return {
        ...prev,
        name: name,
      };
    });
  };

  const onChangeOrder = (order: number) => {
    setMission((prev) => {
      return {
        ...prev,
        order: order,
      };
    });
  };

  const onChangePage = async (
    text: string,
    level: number,
    id: string,
    pageType: PageType
  ) => {
    if (text === "<p><br></p>") text = "";

    text = text.replace("<br></p>", "</p>");
    text = await editImageFormat(text);

    setMissionLevels((prev) => {
      return prev.map((item) => {
        if (item.level === level) {
          return {
            ...item,
            [pageType]: item[pageType].map((page) => {
              if (page.id === id) {
                return {
                  ...page,
                  htmlContent: text,
                };
              }
              return page;
            }),
          };
        }
        return item;
      });
    });
  };

  return (
    <div className="">
      <Stepper activeStep={goSteps}>
        <Step onClick={() => setGoSteps(0)} />
        <Step onClick={() => setGoSteps(1)} />
        <Step onClick={() => setGoSteps(2)} />
      </Stepper>

      {goSteps === 0 && (
        <>
          <div className="row borders">
            <StepsMenu currentStep={goSteps} setCurrentStep={setGoSteps} />

            <div className="col-xl-8 p-4">
              <section>
                <label className="form-label">Nível máximo</label>
                <Form.Select
                  value={mission.maxLevel}
                  onChange={(e) => setMissionMaxLevel(parseInt(e.target.value))}
                >
                  {[1, 2, 3].map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Select>
                <div className="control-group form-group">
                  <label className="form-label">Ano</label>
                  <Form.Select
                    value={mission?.yearId}
                    onChange={(e) => onChangeYear(e.target.value)}
                  >
                    <option>Escolha um ano</option>
                    {years.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                        >{`${item.name}`}</option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="control-group form-group">
                  <label className="form-label">Componente curricular</label>
                  <Form.Select
                    value={mission?.courseId}
                    onChange={(e) => onChangeCourse(e.target.value)}
                  >
                    <option>Escolha um componente curricular</option>

                    {courses.map((item) => {
                      return (
                        <option
                          key={item.id}
                          value={item.id}
                        >{`${item.name}`}</option>
                      );
                    })}
                  </Form.Select>
                </div>
                <label className="form-label">Missão</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Missão 1"
                  value={mission.name}
                  onChange={(text) => onChangeName(text.target.value)}
                />
                <label className="form-label">Título</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Rindo ao quadrado"
                  value={mission.title}
                  onChange={(text) => onChangeTitle(text.target.value)}
                />
                <label className="form-label">Ordem da missão</label>
                <input
                  type="number"
                  className="form-control"
                  value={mission.order}
                  onChange={(text) =>
                    onChangeOrder(parseInt(text.target.value))
                  }
                />
                <label className="form-label">Ícone da missão</label>
                <label
                  style={{
                    backgroundColor: "steelblue",
                    color: "white",
                    minWidth: 130,
                    padding: 10,
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                >
                  Selecione o ícone
                  <FileUploader
                    hidden
                    accept="image/svg+xml"
                    name="avatar"
                    randomizeFilename
                    storageRef={storage.ref("images")}
                    onUploadSuccess={handleUploadSuccess}
                    onUploadError={(error) => {
                      alert(error);
                    }}
                  />
                </label>
                {loadingIcon && (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                )}
                {mission.svgIconUrl && (
                  <img
                    src={mission.svgIconUrl}
                    alt="uploaded file"
                    height={50}
                    style={{
                      marginLeft: 25,
                    }}
                  />
                )}
                {err && (
                  <Alert style={{ marginTop: 20 }} variant="danger">
                    {err}
                  </Alert>
                )}
                <FooterSteps
                  goSteps={goSteps}
                  setGoSteps={setGoSteps}
                  validateMissionFields={validateMissionFields}
                  mission={mission}
                  setError={setError}
                  additionalButton={undefined}
                  validateAdditionalButton={undefined}
                  missionLevels={undefined}
                  currentLevel={undefined}
                  PageType={undefined}
                  addNewPage={undefined}
                />
              </section>
            </div>
          </div>
        </>
      )}
      {goSteps === 1 && (
        <div>
          <div className="row borders">
            <StepsMenu currentStep={goSteps} setCurrentStep={setGoSteps} />

            <div className="col-sm-8 p-4">
              <label className="form-label">Nível</label>
              <Form.Select
                value={currentLevel}
                onChange={(e) => {
                  const value = parseInt(e.target.value.toString()) || 0;
                  setCurrentLevel(value);
                }}
              >
                <option>Escolha um Nível</option>
                {Array.from({ length: mission.maxLevel }, (_, i) => i + 1).map(
                  (level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </Form.Select>
              {currentLevel > 0 &&
                missionLevels[currentLevel - 1]?.introduction.map((item) => {
                  return (
                    <div
                      key={item.id}
                      style={{
                        borderWidth: 1.5,
                        marginTop: 20,
                        padding: 15,
                        borderColor: "gray",
                        borderStyle: "solid",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <div>
                          <label className="form-label">Ordem</label>
                          <input
                            type="number"
                            className="form-control"
                            value={item.order}
                            onChange={(text) =>
                              onChangeOrder(parseInt(text.target.value))
                            }
                          />
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            disabled={currentLevel === 0}
                            style={{ alignSelf: "flex-end" }}
                            className="btn btn-primary float-end mt-4"
                            onClick={() => {
                              removePage(
                                missionLevels[currentLevel - 1].id,
                                item.id,
                                PageType.INTRODUCTION
                              );
                            }}
                          >
                            Excluir página
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="form-label">Conteúdo</label>
                        <MotiroEditor
                          width={350}
                          value={item?.htmlContent}
                          onChange={(text) =>
                            onChangePage(
                              text,
                              currentLevel - 1,
                              item.id,
                              PageType.INTRODUCTION
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              {err && (
                <Alert style={{ marginTop: 20 }} variant="danger">
                  {err}
                </Alert>
              )}
            </div>
            <FooterSteps
              goSteps={goSteps}
              setGoSteps={setGoSteps}
              validateMissionFields={validateContentPageFields}
              mission={missionLevels[currentLevel - 1]}
              setError={setError}
              additionalButton="Adicionar outra página de Introdução"
              validateAdditionalButton={validateContentPageFields}
              missionLevels={missionLevels}
              currentLevel={currentLevel}
              PageType={PageType.INTRODUCTION}
              addNewPage={addNewPage}
            />
          </div>
        </div>
      )}
      {goSteps === 2 && (
        <div>
          <div className="row borders">
            <StepsMenu currentStep={goSteps} setCurrentStep={setGoSteps} />

            <div className="col-sm-8 p-4">
              <label className="form-label">Nível</label>
              <Form.Select
                value={currentLevel}
                onChange={(e) => {
                  const value = parseInt(e.target.value.toString()) || 0;
                  setCurrentLevel(value);
                }}
              >
                <option>Escolha um Nível</option>
                {Array.from({ length: mission.maxLevel }, (_, i) => i + 1).map(
                  (level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  )
                )}
              </Form.Select>
              {currentLevel > 0 &&
                missionLevels[currentLevel - 1]?.preTextual.map((item) => {
                  return (
                    <div
                      key={item.id}
                      style={{
                        borderWidth: 1.5,
                        marginTop: 20,
                        padding: 15,
                        borderColor: "gray",
                        borderStyle: "solid",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <div>
                          <label className="form-label">Ordem</label>
                          <input
                            type="number"
                            className="form-control"
                            value={item.order}
                            onChange={(text) =>
                              onChangeOrder(parseInt(text.target.value))
                            }
                          />
                        </div>
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            disabled={currentLevel === 0}
                            style={{ alignSelf: "flex-end" }}
                            className="btn btn-primary float-end mt-4"
                            onClick={() => {
                              removePage(
                                missionLevels[currentLevel - 1].id,
                                item.id,
                                PageType.PRE_TEXTUAL
                              );
                            }}
                          >
                            Excluir página
                          </button>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="form-label">Conteúdo</label>
                        <MotiroEditor
                          value={item?.htmlContent || ""}
                          width={"350px"}
                          onChange={(text) =>
                            onChangePage(
                              text,
                              currentLevel - 1,
                              item.id,
                              PageType.PRE_TEXTUAL
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              {err && (
                <Alert style={{ marginTop: 20 }} variant="danger">
                  {err}
                </Alert>
              )}
            </div>
            <FooterSteps
              goSteps={goSteps}
              setGoSteps={setGoSteps}
              onSave={handleClick}
              validateMissionFields={validateContentPageFields}
              mission={missionLevels[currentLevel - 1]}
              setError={setError}
              additionalButton="Adicionar outra página de Pré-Textual"
              validateAdditionalButton={validateContentPageFields}
              missionLevels={missionLevels}
              currentLevel={currentLevel}
              PageType={PageType.PRE_TEXTUAL}
              addNewPage={addNewPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};
