import firebase from "firebase/compat/app";
import { db } from "../Firebase/firebase";
import getEnvVars from "../environments/environment";
import { generateRandomString } from "../utils/Helpers/uuid";
import { Course } from "../components/Questions/QuestionModel";
import { getCurrentUserEmail } from "./user.api";
import axios from "axios";

export const getAllCourses = async (): Promise<Course[]> => {
  const collectionRef = db.collection(getEnvVars().COURSES);
  const filteredRef = collectionRef.where("deleted", "!=", true);

  const snapshot = await filteredRef.get();
  const items: Course[] = [];
  snapshot.forEach((doc) => {
    const item = doc.data() as Course;
    items.push(item);
  });
  return items.sort((a, b) => a.order - b.order);
};

export const getCourseById = async (id: string): Promise<Course> => {
  const collectionRef = db.collection(getEnvVars().COURSES);
  const doc = await collectionRef.doc(id).get();
  return doc.data() as Course;
};

export const deleteCourseById = async (id: string) => {
  const collectionRef = db.collection(getEnvVars().COURSES);
  await collectionRef.doc(id).update({
    deleted: true,
    deletedAt: getFirebaseTimestamp(),
    deletedBy: getCurrentUserEmail(),
  });
};

const createNewCourse = async (course: Course, newId: string) => {
  const newCourse = { ...course, id: newId };
  const courseRef = db.collection(getEnvVars().COURSES).doc(newId);
  try {
    await courseRef.set(newCourse);
    alert("Componente curricular adicionado com sucesso!");
  } catch (error) {
    console.error("Erro ao adicionar o componente curricular: ", error);
  }
};

const afterCreateCourse = async (courseId: string) => {
  const url = `https://southamerica-east1-acerta-brasil-estudante.cloudfunctions.net/addCourseToStudents-${
    getEnvVars().CURRENT_ENVIRONMENT
  }`;
  const headers = {
    "Content-Type": "application/json",
  };
  const data = {
    courseId,
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error(`Error creating course: ${error}`);
    throw error;
  }
};

const addExistingCourse = async (course: Course) => {
  const courseRef = db.collection(getEnvVars().COURSES).doc(course.id);
  try {
    await courseRef.set(course);
    alert("Componente curricular adicionado com sucesso!");
  } catch (error) {
    console.error("Erro ao adicionar o componente curricular: ", error);
  }
};

const getFirebaseTimestamp = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const createCourse = async (course: Course) => {
  const courseRef = db.collection(getEnvVars().COURSES).doc(course.id);
  try {
    const doc = await courseRef.get();
    if (doc.exists) {
      const newId = generateRandomString();
      await createNewCourse(
        {
          ...course,
          deleted: false,
          createdAt: getFirebaseTimestamp(),
          createdBy: getCurrentUserEmail(),
        },
        newId
      );
      await afterCreateCourse(newId);
    } else {
      await addExistingCourse({
        ...course,
        deleted: false,
        createdAt: getFirebaseTimestamp(),
        createdBy: getCurrentUserEmail(),
      });
      await afterCreateCourse(course.id);
    }
  } catch (error: any) {
    alert("Erro ao obter o documento: " + error);
  }
};

export const updateCourse = async (course: Course) => {
  const collectionRef = db.collection(getEnvVars().COURSES);
  try {
    await collectionRef.doc(course.id).update({
      ...course,
      updatedAt: getFirebaseTimestamp(),
      updatedBy: getCurrentUserEmail(),
    });
    alert("Componente curricular atualizado com sucesso!");
  } catch (error) {
    alert("Erro ao atualizar o componente curricular: " + error);
  }
};
