import { FC } from "react";
import styles from "./Mission.module.scss";
import { Row, Col, Card } from "react-bootstrap";
import { MissionComponent } from "./Mission";
import PageHeader from "../../Layouts/PageHeader/PageHeader";

interface MissionProps {}

const MissionContainer: FC<MissionProps> = () => (
  <div className={styles.FormWizard}>
    <PageHeader titles="Missões" active="Form-Wizard" items={["Forms"]} />
    <Row>
      <Col md={12}>
        <Card>
          <Card.Header className="border-bottom-0">
            <Card.Title>Missão</Card.Title>
          </Card.Header>
          <Card.Body>
            <MissionComponent />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    {/* <!-- /Row --> */}
  </div>
);

export default MissionContainer;
