import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDR9p7b6b6q4dCbaPWBantQhldjE0zjRq8",
  authDomain: "acerta-brasil-estudante.firebaseapp.com",
  databaseURL: "https://acerta-brasil-estudante-default-rtdb.firebaseio.com",
  projectId: "acerta-brasil-estudante",
  storageBucket: "acerta-brasil-estudante.appspot.com",
  messagingSenderId: "331651526276",
  appId: "1:331651526276:web:f3ac827b71284519a5dcd1",
  measurementId: "G-JDC5570ZYV",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
const appAuth = getAuth(firebaseApp);

const auth = firebase.auth();

export { db, auth, storage, appAuth };
